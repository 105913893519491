import React, { useContext, useEffect } from 'react'
import { MultiSelect } from 'react-multi-select-component'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { useUserCategoryTypes } from '@helpers/auth-provider'
import {
  Filter,
  FilterItem,
  LayoutFilter,
} from '@interfaces/analytics-risk-filter-type-key'
import { Option, Select } from '@material-tailwind/react'
import FilterService from '@services/api-analytics/risk-filter'

interface FilterTypeKeyProps {
  use_filter_exclusion?: boolean
}
const FilterTypeKey = ({ use_filter_exclusion = true }: FilterTypeKeyProps) => {
  const { user_category_types } = useUserCategoryTypes()
  const {
    company,
    activeFilters,
    setActiveFilters,
    optionFilters,
    setOptionFilters,
  } = useContext(AuthContext)

  const { categoryTypes = [], activeType } = activeFilters
  const {
    categoryTypeOptions = [],
    categoryTypeCompanySlugName,
    categoryTypeLoading,
  } = optionFilters
  const active_user_category_types = (
    user_category_types?.split(',') ?? []
  ).filter((x: any) => x)

  const filteredCategoryTypes = categoryTypeOptions.filter((v: Filter) => {
    if (use_filter_exclusion) {
      return (
        v.type_val.filter(
          (tv: FilterItem) => !active_user_category_types.includes(`${tv.id}`)
        ).length > 0
      )
    }
    return v
  })

  const oriActiveVal =
    filteredCategoryTypes?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const activeVal = oriActiveVal.filter((v: FilterItem) => {
    return use_filter_exclusion
      ? !active_user_category_types.includes(`${v.id}`)
      : v
  })

  const allBucket =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === 'All')
      ?.type_val ?? []
  const allCategoryTypes = allBucket.map((av: FilterItem) => av.id)

  useEffect(() => {
    if (company && company.slug_name !== categoryTypeCompanySlugName) {
      setOptionFilters((prev: LayoutFilter) => ({
        ...prev,
        categoryTypeLoading: true,
      }))
      FilterService.getFilters(company?.schema_name, company?.slug_name)
        .then(response => {
          const filters = (response.data ?? []).filter(
            x => x.type_val.length <= 20
          )
          const allValues = filters?.reduce(
            (prev: FilterItem[], cur) => [...prev, ...cur.type_val],
            []
          )

          //adds "all" as an option
          setOptionFilters((prev: LayoutFilter) => ({
            ...prev,
            categoryTypeCompanySlugName: company.slug_name,
            categoryTypeOptions: [
              { type_key: 'All', type_val: allValues },
              ...filters,
            ],
            categoryTypeLoading: false,
          }))

          // selects "all" filters by default if no active filters found (i.e first load)
          categoryTypes?.length === 0 &&
            setActiveFilters((prev: LayoutFilter) => ({
              ...prev,
              categoryTypes: allValues.map(av => av.id).sort(),
              activeType: 'All',
            }))
        })
        .catch(() => {
          setOptionFilters((prev: LayoutFilter) => ({
            ...prev,
            categoryTypeLoading: false,
          }))
        })
    }
  }, [company])

  useEffect(() => {
    const nextCategoryTypes = activeVal.map((av: FilterItem) => av.id)
    /*
     * set categorytypes when:
     * prev activeType equal 'All' OR
     * next activeType equal 'All' OR
     * prev activetype NOT equal 'All' AND there is NO subset between categoryType (prev) and nextCategoryType (next)
     */
    if (
      categoryTypes.sort().join('.') === allCategoryTypes.sort().join('.') ||
      nextCategoryTypes.sort().join('.') ===
        allCategoryTypes.sort().join('.') ||
      (categoryTypes.sort().join('.') !== allCategoryTypes.sort().join('.') &&
        !nextCategoryTypes.some((x: number) => categoryTypes.includes(x)))
    ) {
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        categoryTypes: nextCategoryTypes.sort(),
      }))
    }
  }, [activeType])

  const preselected_filter_ids = allBucket?.filter((act: FilterItem) =>
    categoryTypes.includes(act.id)
  )

  return (
    <div className="flex flex-col gap-1 min-w-[160px]">
      <Typography className="text-neutral-body-2 text-sm">
        Display by
      </Typography>
      <div className="flex gap-2 sm:gap-4">
        {!categoryTypeLoading && (categoryTypeOptions?.length ?? 0) > 0 && (
          <Select
            className=" text-neutral-black min-w-[180px] p-4"
            value={activeType}
            onChange={val => {
              setActiveFilters((prev: LayoutFilter) => ({
                ...prev,
                activeType: val,
              }))
            }}
          >
            {categoryTypeOptions?.map((cto: Filter) => (
              <Option key={cto.type_key} value={cto.type_key}>
                {cto.type_key === 'All'
                  ? 'Select'
                  : cto.type_key.replaceAll('_', ' ')}
              </Option>
            ))}
          </Select>
        )}
        {activeType !== 'All' && (
          <MultiSelect
            className="h-full min-w-[180px] z-20"
            options={activeVal.map((v: FilterItem) => ({
              label: v.type,
              value: v.id,
            }))}
            value={preselected_filter_ids.map((v: FilterItem) => ({
              label: v.type,
              value: v.id,
            }))}
            onChange={(value: any) => {
              setActiveFilters((prev: LayoutFilter) => ({
                ...prev,
                categoryTypes: value.map((v: any) => v.value),
              }))
            }}
            labelledBy=""
            hasSelectAll={false}
            disableSearch={true}
            ClearSelectedIcon={null}
            valueRenderer={selected => `${selected.length} selected`}
          />
        )}
      </div>
    </div>
  )
}
export default FilterTypeKey
