import React from 'react'

import {
  ToggleInfo,
  ToggleProps,
} from '@components/selectors/multi-option-buttons'

// Creates tool tip divs based on toggles and selected toggles
export function generateToolTipInfo(
  toggles: ToggleProps[],
  selection: number[]
) {
  const toolTipObjs: ToggleInfo[] = toggles.map((t: ToggleProps, i) => {
    return t.toggleSelections[selection[i]]
  })

  const output: any = []
  toolTipObjs.forEach(toggleInfo => {
    if (toggleInfo.textInfo) {
      const local: JSX.Element[] = [
        <div key="primary">{toggleInfo.textInfo.primary}</div>,
      ]

      if (toggleInfo.textInfo.secondary) {
        local.push(
          <div key="secondary">
            <br />
            {toggleInfo.textInfo.secondary}
          </div>
        )
      }

      if (toggleInfo.textInfo.tertiary) {
        local.push(
          <div key="tertiary">
            <br />
            {toggleInfo.textInfo.tertiary}
          </div>
        )
      }

      output.push(<div key={toggleInfo.label}>{local}</div>)
    }
  })

  return <div>{output}</div>
}
