import React, { useEffect, useState } from 'react'

import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Alert } from '@material-tailwind/react'

import { SeriesData } from '.'

interface PrecacheLoadingStateProps {
  data: SeriesData[]
  height?: string | number // Make height optional
}

const GRACE_PERIOD_MS = 10000

// Helper for determining if loaded data is empty or just in loading state. Set to 3000 MS of grace period before determining there is no available data to display
const PrecacheLoadingState: React.FC<PrecacheLoadingStateProps> = ({
  data,
  height,
}) => {
  const [graced, setGraced] = useState(false)

  useEffect(() => {
    if (data.length === 0) {
      const timeout = setTimeout(() => {
        setGraced(true)
      }, GRACE_PERIOD_MS)
      return () => clearTimeout(timeout)
    }
  }, [data])

  return (
    <div
      style={height ? { minHeight: height } : {}}
      className={`flex flex-col w-full min-h-[400px] justify-center items-center`}
    >
      {graced ? (
        <Alert className="w-1/2 font-300 text-cc-primary-gray-medium text-center">
          No available data to display
        </Alert>
      ) : (
        <ArrowPathIcon className="animate-spin text-primary-main w-8" />
      )}
    </div>
  )
}

export default PrecacheLoadingState
