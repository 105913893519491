import { useState } from 'react'

import { ToggleProps } from './multi-option-buttons'
interface UseToggleStateProps {
  currTabs: number[]
  mapToggleChange: (toggles: ToggleProps[]) => ToggleProps[]
}

const useToggleState = (initialIndices: number[]): UseToggleStateProps => {
  // curr tabs is an array of tabs that each page uses. Make sure you have the number tabs correct during initialization
  const [currTabs, setCurrTabs] = useState<number[]>(initialIndices)

  const handleToggleChange = (toggleIndex: number, index: number) => {
    const updatedTabs = [...currTabs]
    updatedTabs[toggleIndex] = index
    setCurrTabs(updatedTabs)
  }

  // Make sure to map your set of toggles with this function before to enable actions to each of your toggles.
  const mapToggleChange = (toggles: ToggleProps[]) => {
    return toggles.map((toggle, toggleIndex) => ({
      ...toggle,
      toggleSelections: toggle.toggleSelections.map(
        (selection, labelIndex) => ({
          ...selection,
          active: currTabs[toggleIndex] === labelIndex,
          action: () => {
            handleToggleChange(toggleIndex, labelIndex)
            selection.action()
          },
        })
      ),
    }))
  }

  return { currTabs, mapToggleChange }
}

export default useToggleState
