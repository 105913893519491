import React from 'react'

import { Option } from './index'

interface DropdownListProps {
  options: Option[]
  handleSelect: (option: Option) => any
}

export const DropdownList: React.FC<DropdownListProps> = ({
  options,
  handleSelect,
}) => {
  return (
    <div className="w-full overflow-y-auto z-[9999] ">
      <div className="rounded-lg">
        <ul className="list-reset">
          {options.map(op => (
            <li
              key={op.value}
              className={`${
                op.disabledHint
                  ? 'cursor-not-allowed bg-gray-300'
                  : 'cursor-pointer hover:bg-primary-surface-2'
              } p-4 px-5`}
              onClick={() => handleSelect(op)}
            >
              <div className="flex justify-between">
                <span>
                  {op.label}
                  {op.displayhint && <sup>{op.disabledHint}</sup>}
                </span>
                {op.actionIcon}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
