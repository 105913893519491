import React from 'react'

import { NoSymbolIcon } from '@heroicons/react/24/outline'

const borderXY = '17.2'

/**
 * Intended usage is to house only a single icon - this uses children
 * as a convenience instead of props.  Try something like the following:
 *
 * <BannedActionIcon>
 *   <CloudUploadIcon/>
 * </BannedActionIcon>
 *
 * The lower-right offset was chosen over a complete overlay (like the
 * no smoking sign) because artwork isn't consistently recognizable below
 * the no-symbol icon.
 */
export const BannedActionIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => (
  <svg
    {...props}
    ref={ref}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6"
  >
    <g transform="translate(-0.1, -2) scale(0.9)">{props.children}</g>
    <circle cx={borderXY} cy={borderXY} r="7.5" fill="white" />
    <circle cx={borderXY} cy={borderXY} r="6.2" fill="red" />
    <circle cx={borderXY} cy={borderXY} r="5" fill="white" />
    <g transform="translate(10, 10) scale(0.6)">
      <NoSymbolIcon className="stroke-red" />
    </g>
  </svg>
))
BannedActionIcon.displayName = 'BannedActionIcon'
