import React, { useContext } from 'react'

import AuthContext from '@contexts/auth'
import { Option, Select, Typography } from '@material-tailwind/react'

const statuses = [
  {
    status: undefined,
    display: 'All',
  },
  { status: 'needs_review', display: 'In Review' },
  { status: 'processing', display: 'Processing' },
  { status: 'processed', display: 'Processed' },
  { status: 'voided', display: 'Voided' },
]

const FilterFinancialUploadStatus = () => {
  const { activeFilters, setActiveFilters } = useContext(AuthContext)

  const { financialUploadStatus } = activeFilters

  const getStatusDisplayName = (val: any) => {
    const status = statuses.find((s: any) => s.display === val?.display)
    return status ? status.display : 'All'
  }

  return (
    <div className="flex flex-col gap-1 min-w-[150px]">
      <Typography className="text-neutral-body-2 text-sm">Status</Typography>

      <Select
        className="w-full [&~ul]:max-h-[100px] border border-neutral-border-1"
        onChange={val => {
          setActiveFilters({
            ...activeFilters,
            financialUploadStatus: val,
          })
        }}
        selected={() => (
          <span>{getStatusDisplayName(financialUploadStatus)}</span>
        )}
      >
        {statuses.map((e: any, i: number) => (
          <Option key={i} value={e}>
            {e.display}
          </Option>
        ))}
      </Select>
    </div>
  )
}
export default FilterFinancialUploadStatus
