import React, { FC } from 'react'

import Typography from '@components/atoms/typography'

interface FilterCurrencyProps {
  title: string
}
export const FilterCell: FC<FilterCurrencyProps> = ({ title, children }) => {
  return (
    <div className="rounded-lg flex flex-col flex-grow gap-1 min-w-[90px]">
      <Typography className="text-neutral-body-2 text-sm">{title}</Typography>
      {children}
    </div>
  )
}
