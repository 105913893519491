/* eslint-disable */
import React from 'react'

interface ReusableButtonProps {
  onClick?: () => void
  className?: string
  deselectColor?: string
  hoverColor?: string
  children: React.ReactNode
  disabled?: boolean
}

/** Default Orange Black button we can reused */
const CCButton: React.FC<ReusableButtonProps> = ({
  onClick,
  className = '',
  deselectColor = 'primary-main',
  hoverColor = 'primary-hover',
  children,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${className} flex justify-center items-center px-5 py-2 text-sm font-medium rounded-lg text-white  ${
        disabled
          ? `bg-cc-primary-gray-light cursor-not-allowed`
          : `bg-${deselectColor} hover:bg-${hoverColor}  transition ease-in-out delay-50 duration-300`
      }`}
    >
      {children}
    </button>
  )
}

export default CCButton
