import React from 'react'

import { XMarkIcon } from '@heroicons/react/24/solid'

interface DialogProps {
  isOpen: boolean
  onClose: () => void
  title: string
  children: React.ReactNode
}

interface DialogBodyProps {
  children: React.ReactNode
}

interface DialogFooterProps {
  children: React.ReactNode
}

// Main Dialog Component
const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  title,
  children,
}) => {
  return (
    <div
      className={`fixed inset-0 z-[999] flex items-center justify-center transition-all backdrop-blur-md duration-300 ${
        isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
      }`}
    >
      {/* Background Overlay */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>

      {/* Dialog Box */}
      <div
        className={`relative z-10 bg-white p-6 rounded-lg shadow-lg w-[650px] transition-all ease-in-out duration-500 ${
          isOpen ? 'blur-none' : 'blur-lg'
        }`}
      >
        <div className="flex justify-between mb-5">
          <div className="text-cc-text-primary font-medium text-xl">
            {title}
          </div>
          <button
            className="text-gray-500 hover:text-gray-800"
            onClick={onClose}
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}

// Dialog Body Component
const DialogBody: React.FC<DialogBodyProps> = ({ children }) => {
  return (
    <div className="flex flex-col gap-6 mb-6">
      <div className="border-t border-cc-primary-gray-light w-full" />
      {children}
      <div className="border-t border-cc-primary-gray-light w-full" />
    </div>
  )
}

// Dialog Footer Component
const DialogFooter: React.FC<DialogFooterProps> = ({ children }) => {
  return <div className="flex justify-end gap-4">{children}</div>
}

// Export all components from one file
export { Dialog, DialogBody, DialogFooter }
