import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

import { useFlaggedRoutes } from '@components/app-routes/routes'
import AuthContext from '@contexts/auth'
import {
  Bars3BottomRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  IconButton,
} from '@material-tailwind/react'

const AccordionIcon = ({ open }: { open: boolean }) => {
  const Icon = open ? ChevronUpIcon : ChevronDownIcon
  return <Icon className="w-4 h-4" />
}

const MenuIcon = ({ expanded }: { expanded: boolean }) => {
  const Icon = expanded ? XMarkIcon : Bars3BottomRightIcon
  return <Icon className="w-6 text-neutral-body-2" />
}

interface SidemenuProps {
  /**
   * whether L2/3 menu is expanded or not
   */
  expanded: boolean
  /**
   * function triggered while click on close button
   */
  onExpand: () => void
  /**
   * whether this sidemenu shown or not
   */
  show: boolean
  /**
   * function triggered while click on show side menu button
   */
  onShow: () => void
}

const Sidemenu = ({ expanded, onExpand, show, onShow }: SidemenuProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const context = useContext(AuthContext)

  const { pathname } = location

  const getFlaggedRouting = useFlaggedRoutes(context)
  const flaggedRouting = getFlaggedRouting()
  const levelOne = flaggedRouting.find(r => pathname.startsWith(`/${r.path}`))

  /**
   * temp workaround for this issue
   * https://github.com/creativetimofficial/material-tailwind/pull/206
   */
  const [mounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 500)
    return () => {
      setMounted(false)
    }
  }, [show])
  /** end workaround */

  const Icon = show ? ChevronLeftIcon : ChevronRightIcon

  const [searchParams] = useSearchParams()

  return (
    <>
      <div
        className={`w-screen lg:w-sidemenu peer h-full bg-neutral-white flex flex-col z-12 border-l border-neutral-border-1 z-10 ${
          show ? 'lg:flex' : 'lg:hidden'
        }`}
      >
        <div className="flex m-4 items-center">
          <div className="flex-1 rounded bg-cc-primary-gray-dark text-neutral-white px-3 py-3 text-sm font-medium border-b border-neutral-border-1">
            {levelOne?.title}
          </div>
          <div className="ml-6 lg:hidden">
            <IconButton
              onClick={onExpand}
              variant="outlined"
              className="w-[40px] h-[40px] rounded"
            >
              <MenuIcon expanded={expanded} />
            </IconButton>
          </div>
        </div>

        <hr className="border-neutral-border-1" />
        <div
          className={`overflow-y-auto ${
            expanded ? 'visible' : 'invisible'
          } lg:visible`}
        >
          {levelOne?.routes?.map(levelTwo => {
            if (!levelTwo.routes) {
              return (
                <NavLink
                  to={{
                    pathname: `/${levelOne.path}/${levelTwo.path}`,
                    search: searchParams.toString(),
                  }}
                  key={levelTwo.path}
                  className={({ isActive }) =>
                    `flex p-4 font-medium ${
                      isActive
                        ? 'bg-primary-surface-2 text-primary-main'
                        : 'text-neutral-body-2'
                    }`
                  }
                >
                  {levelTwo.title}
                </NavLink>
              )
            }
            const L2Path = `/${levelOne.path}/${levelTwo.path}`
            const open = mounted && pathname.startsWith(L2Path)
            return (
              <Accordion
                key={levelTwo.path}
                open={open}
                icon={<AccordionIcon open={open} />}
                className="flex flex-col border-neutral-border-1 border-b"
              >
                <AccordionHeader
                  onClick={() => navigate(L2Path)}
                  className={`px-7 py-6 font-bold text-neutral-subtitle-2 text-sm border-0 hover:bg-cc-secondary-hover-deselected hover:text-primary-main ${
                    mounted && pathname === `${L2Path}/overview`
                      ? 'bg-primary-surface-2 text-primary-main'
                      : ''
                  }`}
                >
                  {levelTwo.title}
                </AccordionHeader>
                <AccordionBody className="flex flex-col p-0 pt-0">
                  {levelTwo.routes?.map(levelThree =>
                    levelThree.menuHidden === false ? null : (
                      <NavLink
                        to={{
                          pathname: `/${levelOne.path}/${levelTwo.path}/${levelThree.path}`,
                          search: searchParams.toString(),
                        }}
                        key={levelThree.path}
                        className={({ isActive }) =>
                          `py-3 px-7 font-medium hover:bg-cc-secondary-hover-deselected hover:text-primary-main ${
                            isActive
                              ? 'bg-primary-surface-2 text-primary-main'
                              : 'text-neutral-subtitle-2'
                          }`
                        }
                        onClick={onExpand}
                      >
                        {levelThree.title}
                      </NavLink>
                    )
                  )}
                </AccordionBody>
              </Accordion>
            )
          })}
        </div>
      </div>
      <IconButton
        id="expand-nav"
        onClick={onShow}
        variant="text"
        className={`w-10 h-10 bg-neutral-white hover:bg-secondary-surface rounded-full transition-none invisible lg:peer-hover:visible lg:hover:visible ${
          show ? '' : 'lg:visible'
        } !absolute top-[50vh] -translate-y-1/2 -right-[20px] z-[15] border border-neutral-border-2`}
      >
        <Icon className="w-4 text-neutral-body-2" />
      </IconButton>
    </>
  )
}

export default Sidemenu
