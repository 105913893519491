import React, { FC, useState } from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import MultiToggles from '@components/selectors/multi-option-buttons'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Switch } from '@material-tailwind/react'
import { Tooltip } from '@material-tailwind/react'

import { generateToolTipInfo } from './tooltip-generator'

interface MultiToggleLayoutProps {
  toggles: ToggleProps[] | false
  toggleSelection?: number[]
  disableTooltip?: boolean
  exchangeRateIndicator?: boolean
  isHistorical?: boolean
  /**
   * custom content
   */
  staticTipInfo?: JSX.Element // Tool tip that is not dynamic at top of dialog
  chart?: JSX.Element
  stat?: JSX.Element
  toggleData?: JSX.Element
}

/**
 * Multi-toggle layout is stateless. it just renders based on logic.
 * @param {MultiToggleLayoutProps} props Component properties
 * @returns JSX
 */
const MultiToggleLayout: FC<MultiToggleLayoutProps> = ({
  toggles,
  toggleSelection,
  disableTooltip = false,
  exchangeRateIndicator = false,
  isHistorical = true,
  staticTipInfo,
  chart,
  stat,
  toggleData,
}) => {
  const [showData, setShowData] = useState(true)

  const toolTip: JSX.Element = (
    <div className="flex flex-col gap-4">
      {staticTipInfo && staticTipInfo}
      {toggles &&
        toggleSelection &&
        generateToolTipInfo(toggles, toggleSelection)}
    </div>
  )

  return (
    <div className="flex flex-col gap-4">
      {toggles && toggles.some(t => t.toggleSelections.length > 1) && (
        <div className="mb-4">
          <MultiToggles mulitToggles={toggles} />
        </div>
      )}

      <div className="flex flex-col gap-3 lg:flex-row lg:items-center">
        <div className="flex-1 w-full lg:w-[calc(100%-167px)]">{chart}</div>
        {stat && (
          <div className="border-t border-t-neutral-border-2 lg:border-0">
            {stat}
          </div>
        )}
      </div>

      <div className="flex justify-between">
        {toggleData && (
          <div className="flex gap-3">
            <Switch
              checked={showData}
              onChange={() => {
                setShowData(!showData)
              }}
              defaultChecked
              className="checked:bg-[#ff7d35]"
              crossOrigin={undefined}
            />
            <div className="text-sm flex items-center text-cc-text-primary">
              Show Data
            </div>
          </div>
        )}

        <div className="flex gap-2 ml-auto">
          {exchangeRateIndicator && (
            <div className="flex items-center">
              <ExchangeRateBadge isHistorical={isHistorical} />
            </div>
          )}
          {!disableTooltip && toolTip && (
            <Tooltip content={toolTip} placement="right">
              <InformationCircleIcon className="h-7 text-primary-main cursor-help" />
            </Tooltip>
          )}
        </div>
      </div>
      {showData && toggleData}
    </div>
  )
}

export default MultiToggleLayout
