import React, { useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import AuthCallback from 'src/pages/auth-callback'
import AuthHandler from 'src/pages/auth-handler'
import MaintenancePage from 'src/pages/maintenance'
import NoMatchPage from 'src/pages/no-match'

import { useFlaggedRoutes } from '@components/app-routes/routes'
import AuthLayout from '@components/layouts/auth-layout'
import { IS_MAINTENANCE } from '@constants/config'
import AuthContext from '@contexts/auth'

/**
 * @todo
 * Make it recursive
 */
const AppRoutes = () => {
  const context = useContext(AuthContext)
  const getFlaggedRouting = useFlaggedRoutes(context)
  const flaggedRouting = getFlaggedRouting()

  if (IS_MAINTENANCE?.toLowerCase() === 'true') {
    return <MaintenancePage />
  }

  return (
    <Routes>
      <Route path="/auth-callback" element={<AuthCallback />} />
      <Route path="/auth-handler" element={<AuthHandler />} />
      <Route path="/" element={<AuthLayout />}>
        {flaggedRouting.map((levelOne, idxOne) => (
          <React.Fragment key={levelOne.path}>
            {idxOne === 0 && (
              <Route index element={<Navigate to={levelOne.path} replace />} />
            )}
            <Route path={levelOne.path}>
              {levelOne.routes?.map((levelTwo, idxTwo) => (
                <React.Fragment key={levelTwo.path}>
                  {idxTwo === 0 && (
                    <Route
                      index
                      element={<Navigate to={levelTwo.path} replace />}
                    />
                  )}
                  <Route
                    path={levelTwo.path}
                    element={
                      (!levelTwo.routes || levelTwo.component) &&
                      levelTwo.component
                    }
                  >
                    {levelTwo.routes?.map((levelThree, idxThree) => (
                      <React.Fragment key={levelThree.path}>
                        {idxThree === 0 && (
                          <Route
                            index
                            element={<Navigate to={levelThree.path} replace />}
                          />
                        )}
                        <Route
                          path={levelThree.path}
                          element={levelThree.component}
                        ></Route>
                      </React.Fragment>
                    ))}
                  </Route>
                </React.Fragment>
              ))}
            </Route>
          </React.Fragment>
        ))}
        <Route path="/404" element={<NoMatchPage />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
