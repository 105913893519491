import React, { useContext, useEffect } from 'react'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { ShieldCheckIcon } from '@heroicons/react/24/outline'
import { LayoutFilter } from '@interfaces/filter'
import { EntityInfo } from '@interfaces/financial'
import { Option, Select } from '@material-tailwind/react'
import { EntityService } from '@services/api-admin/settings-entities'
import FilterService from '@services/api-analytics/risk-traction-filter'

const FilterFinancialDisplay = () => {
  const {
    company,
    appliedFilters,
    activeFilters,
    setActiveFilters,
    setAppliedFilters,
    optionFilters,
    setOptionFilters,
  } = useContext(AuthContext)

  const { financialEntityID, currency } = activeFilters
  const { financialEntities } = optionFilters

  useEffect(() => {
    if (company) {
      FilterService.getEntities(company.slug_name).then(res => {
        setOptionFilters((prev: LayoutFilter) => ({
          ...prev,
          financialEntities: res.data,
        }))

        setActiveFilters((prev: LayoutFilter) => {
          return {
            ...prev,
            financialEntityID: prev.financialEntityID ?? res.data[0]?.entity_id,
            financialEntityCurrency:
              prev.financialEntityCurrency ?? res.data[0]?.currency,
          }
        })

        setAppliedFilters((prev: LayoutFilter) => {
          return {
            ...prev,
            financialEntityID: prev.financialEntityID ?? res.data[0]?.entity_id,
            financialEntityCurrency:
              prev.financialEntityCurrency ?? res.data[0]?.currency,
          }
        })
      })
      EntityService.getFilterEntities({ slugName: company?.slug_name }).then(
        res => {
          setOptionFilters((prev: LayoutFilter) => ({
            ...prev,
            entities: res,
          }))
        }
      )
    }
  }, [company])

  useEffect(() => {
    const appliedEntity = financialEntities?.find(
      (entity: EntityInfo) =>
        entity.entity_id == appliedFilters.financialEntityID
    )
    setActiveFilters((prev: LayoutFilter) => {
      return {
        ...prev,
        currency: appliedEntity?.currency ?? currency,
      }
    })
    setAppliedFilters((prev: LayoutFilter) => {
      return {
        ...prev,
        currency: appliedEntity?.currency ?? currency,
      }
    })
  }, appliedFilters.financialEntityID)

  const getEntityDisplayName = (entity: string) => {
    let name = ''
    financialEntities.map((en: EntityInfo) => {
      if (en.entity_id == entity) {
        name = en.name
      }
    })
    return name
  }

  return (
    <div className="flex flex-col gap-1 min-w-[150px]">
      {!!financialEntities && financialEntities.length > 0 && (
        <>
          <Typography className="text-neutral-body-2 text-sm">
            Entities
          </Typography>
          <Select
            className={`[&~ul]:max-h-${
              financialEntities?.length > 3 ? '[200px]' : '[100px]'
            } bg-neutral-white text-neutral-black`}
            onChange={val => {
              const enCurrency = financialEntities.find(
                (e: EntityInfo) => e.entity_id == val
              ).currency
              setActiveFilters({
                ...activeFilters,
                financialEntityID: val,
                financialEntityCurrency: enCurrency,
                currency: enCurrency,
              })
            }}
            selected={() => {
              return (
                <span className="flex">
                  {' '}
                  <ShieldCheckIcon className="w-4 mr-2" />
                  {getEntityDisplayName(financialEntityID)}
                </span>
              )
            }}
          >
            {financialEntities.map((e: EntityInfo, i: number) => (
              <Option key={i} value={e.entity_id}>
                {e.name}
              </Option>
            ))}
          </Select>
        </>
      )}
    </div>
  )
}
export default FilterFinancialDisplay
