import React from 'react'

import { CheckCircleIcon, ListBulletIcon } from '@heroicons/react/24/outline'
import {
  CheckCircleIcon as CheckCircleIconSolid,
  ListBulletIcon as ListBulletIconSolid,
} from '@heroicons/react/24/solid'

const borderXY = '17.2'

export const DataValidationIconOutline = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => (
  <svg
    {...props}
    ref={ref}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6"
  >
    <g transform="translate(-0.1, -2) scale(0.9)">
      <ListBulletIcon />
    </g>
    <circle cx={borderXY} cy={borderXY} r="7.5" fill="white" />
    <circle cx={borderXY} cy={borderXY} r="6.2" fill="black" />
    <circle cx={borderXY} cy={borderXY} r="5" fill="white" />
    <g transform="translate(10, 10) scale(0.6)">
      <CheckCircleIcon />
    </g>
  </svg>
))
DataValidationIconOutline.displayName = 'DataValidationIconOutline'

export const DataValidationIconSolid = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => (
  <svg
    {...props}
    ref={ref}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6"
  >
    <g transform="translate(-0.1, -2) scale(0.9)">
      <ListBulletIconSolid />
    </g>
    <circle cx={borderXY} cy={borderXY} r="7.5" fill="white" />
    <circle cx={borderXY} cy={borderXY} r="6.2" fill="black" />
    <circle cx={borderXY} cy={borderXY} r="5" fill="white" />
    <g transform="translate(10, 10) scale(0.6)">
      <CheckCircleIconSolid />
    </g>
  </svg>
))

DataValidationIconSolid.displayName = 'DataValidationIconSolid'
