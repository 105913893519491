import React, { useEffect, useRef, useState } from 'react'

import { DropdownList } from './DropdownList'

export interface Option {
  actionIcon: any
  label: string
  value: any
  disabledHint: string
  displayhint: boolean
}

export interface OptionSearchProps {
  defaultValue?: string
  availableOptions: Option[]
  searchParam: string
  showOptions: boolean
  handleChange: (value: string) => void
  setOpenSwitcher?: React.Dispatch<React.SetStateAction<boolean>>
}

const OptionSearch = ({
  defaultValue,
  availableOptions,
  searchParam,
  handleChange,
  showOptions,
  setOpenSwitcher = undefined,
}: OptionSearchProps) => {
  const [query, setQuery] = useState('')
  const [options, setOptions] = useState<Option[]>([])
  const [optionsShown, setOptionsShown] = useState<boolean>(showOptions)
  const [displayVal, setDisplayVal] = useState<string>('')
  const containerRef = useRef<HTMLDivElement>(null)

  const filteredOptions = options.filter(o =>
    o.label?.toLowerCase().includes(query?.toLowerCase())
  )

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
    setDisplayVal(event.target.value)
    setOptionsShown(true)
  }

  const handleSelect = (option: Option) => {
    setOptionsShown(false)
    setDisplayVal('')
    setQuery(option.label)
    handleChange(option.value)
  }

  useEffect(() => {
    const handleExternalClicks = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setOptionsShown(false)
        if (setOpenSwitcher) {
          setOpenSwitcher(false)
        }
      }
    }
    // Add event listener for mousedown
    document.addEventListener('mousedown', handleExternalClicks)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleExternalClicks)
    }
  }, [setOpenSwitcher])

  useEffect(() => {
    defaultValue && setQuery(defaultValue)
    availableOptions && setOptions(availableOptions)
  }, [defaultValue, availableOptions])

  return (
    <div
      ref={containerRef}
      id="test"
      className="bg-white z-[9999] h-full flex flex-col gap-2"
    >
      <input
        className="box-border sticky shadow-lg focus:outline-none focus:shadow-outline-blue border border-gray-300 rounded-lg py-2 px-5 block w-full text-sm"
        type="text"
        placeholder={`Search by ${searchParam}...`}
        value={displayVal}
        onChange={handleInput}
      />
      {displayVal == '' && optionsShown && (
        <DropdownList options={options} handleSelect={handleSelect} />
      )}
      {displayVal != '' && optionsShown && filteredOptions.length > 0 && (
        <DropdownList options={filteredOptions} handleSelect={handleSelect} />
      )}
    </div>
  )
}

export default OptionSearch
