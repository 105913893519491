import React, { useContext, useEffect } from 'react'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { Entity } from '@interfaces/accounting/financials'
import { LayoutFilter } from '@interfaces/analytics-risk-filter-type-key'
import { Option, Select } from '@material-tailwind/react'
import { EntityService } from '@services/api-admin/settings-entities'
import FilterService from '@services/api-analytics/risk-traction-filter'

const FilterEntity = () => {
  const {
    company,
    activeFilters,
    setActiveFilters,
    optionFilters,
    setOptionFilters,
    appliedFilters,
    setAppliedFilters,
  } = useContext(AuthContext)
  const { financialEntityID: appliedFinancialEntityID } = appliedFilters
  const { financialEntityID } = activeFilters
  const { entities = [] } = optionFilters

  useEffect(() => {
    if (company) {
      EntityService.getFilterEntities({ slugName: company?.slug_name }).then(
        res => {
          if (!appliedFinancialEntityID && res?.[0]?.ID) {
            setAppliedFilters((prev: LayoutFilter) => ({
              ...prev,
              financialEntityID: res?.[0]?.ID,
              financialEntityCurrency: res?.[0]?.currency,
            }))
          }
          setActiveFilters((prev: LayoutFilter) => ({
            ...prev,
            financialEntityID: appliedFinancialEntityID ?? res?.[0]?.ID,
            financialEntityCurrency:
              prev.financialEntityCurrency ?? res?.[0]?.currency,
          }))
          setOptionFilters((prev: LayoutFilter) => ({
            ...prev,
            entities: res,
          }))
        }
      )
      FilterService.getEntities(company?.slug_name).then(res => {
        setOptionFilters((prev: LayoutFilter) => ({
          ...prev,
          financialEntities: res.data,
        }))
      })
    }
  }, [company])

  const activeEntityObject = entities?.find(
    (f: Entity) => f.ID === financialEntityID
  )

  return (
    <div className="flex flex-col gap-1 min-w-[150px]">
      <Typography className="text-neutral-body-2 text-sm">Entities</Typography>

      <Select
        className={`[&~ul]:max-h-${
          entities?.length > 3 ? '[200px]' : '[100px]'
        } bg-neutral-white text-neutral-black`}
        disabled={entities.length === 0}
        onChange={val => {
          setActiveFilters((prev: LayoutFilter) => ({
            ...prev,
            financialEntityID: val,
          }))
        }}
        selected={() => {
          return <span>{activeEntityObject?.entityNickname}</span>
        }}
      >
        {entities.map((c: Entity, i: number) => (
          <Option key={i} value={c.ID}>
            {c.entityNickname}
          </Option>
        ))}
      </Select>
    </div>
  )
}
export default FilterEntity
