import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import useHotjar from 'react-use-hotjar'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import { setFilterDocCentre } from '@components/filters/filter-doc-centre'
import AuthContext from '@contexts/auth'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { HomeIcon } from '@heroicons/react/24/solid'
import {
  Filter,
  FilterItem,
  LayoutFilter,
} from '@interfaces/analytics-risk-filter-type-key'
import { Facility } from '@interfaces/facility'
import { EntityInfo } from '@interfaces/financial'
import { Breadcrumbs } from '@material-tailwind/react'
import AnalyticsWarmerService from '@services/api-analytics/cache-warmer'
import ManageWarmerService from '@services/api-manage/cache-warmer'
export interface BaseLayoutProps {
  /**
   * filters section. empty by defaults
   */
  filters?: {
    left?: any
    middle?: any
    right?: any
    className?: string
  }
  /**
   * page title
   */
  title: string

  /**
   * whether reset button disabled or not. Depend on which page observing context.
   */
  disableReset?: boolean

  /**
   * right footer content in jsx
   * currently used for export button
   */
  rightFooterContent?: any

  /**
   * for scenarios where we want to use the tab to dynamically generate what is shown in each (L,M,R) filter
   */
  tab?: number
}

const BaseLayout: FC<BaseLayoutProps> = ({
  children,
  filters,
  disableReset = true,
  rightFooterContent,
}) => {
  const {
    showFilter,
    setShowFilter,
    company,
    optionFilters,
    setOptionFilters,
    activeFilters,
    setActiveFilters,
    appliedFilters,
    setAppliedFilters,
  } = useContext(AuthContext)
  const location = useLocation()
  const { stateChange } = useHotjar()

  const exportDivRef = useRef<HTMLDivElement>(null)

  /* Uses LoDash deepcompare between the 2 filters to detect change. */
  const applyEnabled = !isEqual(activeFilters || {}, appliedFilters || {})

  const disableApply = Object.keys(appliedFilters).length == 0 || !applyEnabled

  const getFiltersSectionArray = (
    section: 'left' | 'middle' | 'right'
  ): Record<string, any>[] => {
    return filters?.[section]
      ? Array.isArray(filters[section])
        ? filters[section]
        : [filters[section]]
      : []
  }

  const getFilterByKey = (key: string) => {
    const filtersList = [
      ...getFiltersSectionArray('left'),
      ...getFiltersSectionArray('middle'),
      ...getFiltersSectionArray('right'),
    ]
    return filtersList.find((f: any) => f.key === key)
  }

  const resetFilter = () => {
    const dateRangeProps = getFilterByKey('date-range-filter')?.props
    const end = dateRangeProps?.isCurrentDate
      ? moment.utc()
      : moment(company?.date_end).isAfter(moment())
      ? moment()
      : moment(company?.date_end)
    const activeFinancialEntity = optionFilters.financialEntities?.length
      ? optionFilters.financialEntities.find(
          (entity: EntityInfo) =>
            entity.entity_id == optionFilters.financialEntities?.[0].entity_id
        )
      : undefined

    const initialFilters = {
      currency: company?.currencies_available?.[0]?.to_currency,
      dateStart: moment(company?.date_start).format('YYYY-MM-DD'),
      dateEnd: dateRangeProps?.addMonthToEndDate
        ? end.add(1, 'months').format()
        : end.format(),
      dateEndCohort: moment(company?.date_end_cohort).isAfter(moment())
        ? moment()
        : moment(company?.date_end_cohort),
      range: 0,
      activeType: 'All',
      categoryTypes: optionFilters.categoryTypeOptions
        ?.find((cto: Filter) => cto.type_key === 'All')
        ?.type_val?.map((tv: FilterItem) => tv.id),
      activeFacilityId: 1,
      dateStartCohort: moment.utc(company?.date_start),
      financialEntityCurrency: activeFinancialEntity
        ? activeFinancialEntity.currency
        : undefined,
      entityStartDate: activeFinancialEntity?.start_date
        ? moment.utc(activeFinancialEntity.start_date).format('yyyy-MM-DD')
        : undefined,
      entityEndDate: activeFinancialEntity?.end_date
        ? moment.utc(activeFinancialEntity.end_date).format('yyyy-MM-DD')
        : undefined,
      financialEntityID: activeFinancialEntity
        ? activeFinancialEntity.entity_id
        : undefined,
      calculationDate: optionFilters.calculationDateOption?.length
        ? optionFilters.calculationDateOption[0]?.calculation_date
        : company?.date_end,
      financialUploadStatus: 'All',
    }
    const firstFacility = optionFilters?.facilities?.find(
      (f: Facility) => f.facility_id === 1
    )
    setActiveFilters(initialFilters)
    setAppliedFilters(initialFilters)
    setOptionFilters((prev: LayoutFilter) => ({
      ...prev,
      calculationDateIsFetching: false,
    }))
    setFilterDocCentre(
      firstFacility,
      company,
      setActiveFilters,
      optionFilters,
      setOptionFilters,
      true
    )
  }

  const applyFilter = () => {
    if (company) {
      if (company.has_analytics) {
        let dates
        try {
          dates = {
            default_from: moment
              .utc(activeFilters.dateStart)
              .format('YYYY-MM-DD'),
            default_to: moment.utc(activeFilters.dateEnd).format('YYYY-MM-DD'),
            cohort_from: moment
              .utc(activeFilters.dateStartCohort)
              .format('YYYY-MM-DD'),
            cohort_to: moment
              .utc(activeFilters.dateEndCohort)
              .format('YYYY-MM-DD'),
          }
        } catch (error) {
          dates
        }

        AnalyticsWarmerService.warmup({
          slug_name: company.slug_name,
          dates,
          currency: activeFilters?.currency,
          filters: activeFilters?.categoryTypes,
        })
      }
      if (company.has_manage) {
        let calculation_date
        try {
          calculation_date = moment
            .utc(activeFilters.calculationDate)
            .format('YYYY-MM-DD')
        } catch (error) {
          calculation_date
        }
        ManageWarmerService.warmup({
          slug_name: company.slug_name,
          calculation_date,
        })
      }
    }
    if (activeFilters.activeFacilityId !== appliedFilters.activeFacilityId) {
      activeFilters.calculationDate = company?.date_end
    }
    setAppliedFilters(activeFilters)
    setShowFilter(!showFilter) //closes filters when new filters are selected
  }
  //preparing breadcrumbs.. yum toast ;)
  const breadcrumbsArr = window.location.pathname.split('/')

  /**
   * temp workaround for this issue
   * https://github.com/creativetimofficial/material-tailwind/pull/206
   */
  const [mounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 500)
    return () => {
      setMounted(false)
    }
  }, [])
  /** end workaround */

  useEffect(() => {
    setShowFilter(false)
    stateChange(window.location.pathname, console.info)
  }, [location])

  const [toggleFilter, setToggleFilter] = useState<boolean>(false)

  useEffect(() => {
    if (mounted) {
      setToggleFilter(true)
    }
  }, [mounted])

  const handleClick = () => {
    setToggleFilter(!toggleFilter)
  }

  return (
    <div className="overflow-y-auto flex flex-col py-4 px-2 gap-y-4 sm:px-6 mt-16 navthreshold:mt-0 lg:py-6 xl:px-8 [&>nav]:w-full">
      <div
        id="filter"
        className="relative flex flex-col py-4 px-6 bg-neutral-white rounded-md shadow"
      >
        <div className="flex flex-col sm:flex-row items-center justify-between w-full ">
          {breadcrumbsArr.length > 1 && (
            <div className="flex items-center">
              <Breadcrumbs
                separator=" > "
                className="bg-transparent p-0 w-full"
              >
                {breadcrumbsArr.map((crumb, i) => (
                  <a
                    key={i}
                    href="#"
                    className="text-sm capitalize text-neutral-body-2"
                  >
                    {crumb == '' ? (
                      <HomeIcon className="w-5 -translate-y-0.5" />
                    ) : (
                      crumb.replaceAll('-', ' ')
                    )}
                  </a>
                ))}
              </Breadcrumbs>
            </div>
          )}
          {filters && (
            <div
              onClick={handleClick}
              className={`flex justify-between gap-2 items-center cursor-pointer bg-white hover:bg-cc-secondary-hover-selected rounded-full py-1 px-3 transition-all ease-in-out duration-160 w-[120px] center translate-y-1 sm:translate-y-0`}
            >
              <Typography className="text-neutral-body-2 text-sm">
                {toggleFilter ? 'Hide Filter' : 'Show Filter'}
              </Typography>
              <ChevronRightIcon
                className={`w-4 transition-transform duration-300 ease-in-out ${
                  toggleFilter ? 'rotate-90' : 'rotate-0'
                }`}
              />
            </div>
          )}
        </div>

        {/* static hor line */}
        {filters && toggleFilter && (
          <div className="absolute bg-cc-primary-border h-[0.075rem] translate-y-[60px] sm:translate-y-11 left-6 right-6"></div>
        )}

        {filters && toggleFilter && (
          <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row sm:flex-wrap sm:justify-between mt-8">
            {filters?.left && <div>{filters?.left}</div>}
            {filters?.middle && (
              <div className="min-w-[260px] ">{filters?.middle}</div>
            )}
            {filters?.right && <div>{filters?.right}</div>}
            <div className="flex ml-auto sm:flex-row gap-2 sm:gap-3 items-end min-h-[64px]">
              <div ref={exportDivRef} id="exportdiv" className="min-h-[40px]">
                {rightFooterContent}
              </div>
              <Button
                id="reset-button"
                className="h-[40px] w-[75px]"
                onClick={resetFilter}
                disabled={disableReset}
              >
                Reset
              </Button>
              <Button
                id="apply-button"
                color="primary"
                onClick={applyFilter}
                disabled={disableApply}
                className="h-[40px] w-[75px]"
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="rounded-md">{children}</div>
    </div>
  )
}

export default BaseLayout
