import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import {
  ArrowDownIcon,
  ArrowPathIcon,
  ArrowUpIcon,
} from '@heroicons/react/24/outline'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Card, CardBody } from '@material-tailwind/react'

import BaseLayout, { BaseLayoutProps } from './base-layout'

interface OverviewItem {
  feature: string
  cardTitle: string
  cardLink: string
  icon: string | 'line' | 'bar'
  fetcher?: (params: any) => void
  fetchOption?: {
    [key: string]: any
  }
  formatter?: (val: number) => void
  secondaryFormatter?: (val: number) => void
  indicatorClass?: {
    up: string
    down: string
  }
}

interface OverviewLayoutProps extends BaseLayoutProps {
  data: OverviewItem[]
  fetchReady?: boolean
}

const OverviewItem = ({
  feature,
  cardTitle,
  cardLink,
  fetcher,
  fetchOption,
  formatter,
  secondaryFormatter,
  indicatorClass = {
    up: 'bg-green',
    down: 'bg-red',
  },
}: OverviewItem) => {
  const { company } = useContext(AuthContext)

  const filters: RisksFilters = {
    slug_name: company?.slug_name ?? '',
    ...fetchOption,
  }

  const { data, isLoading } = useQuery(
    [feature, filters],
    () => fetcher?.(filters),
    getStaleMins()
  )

  const {
    primaryStat,
    primaryStatLabel,
    secondaryStat,
    secondaryStatChange,
    secondaryStatLabel,
  } = data ?? {
    primaryStat: 0,
    primaryStatLabel: '',
    secondaryStat: 0,
    secondaryStatChange: 0,
    secondaryStatLabel: '',
  }

  const Icon = secondaryStatChange < 0 ? ArrowDownIcon : ArrowUpIcon
  return (
    <Link to={cardLink}>
      <Card className="hover:bg-gradient-to-r hover:from-cc-primary-gray-medium/100 hover:to-cc-primary-gray-medium/50 group rounded-md">
        <CardBody className="flex flex-col justify-between h-[300px]">
          <Typography className="text-cc-primary mb-4 font-semibold group-hover:text-neutral-white">
            {cardTitle}
          </Typography>

          <div className="flex flex-col gap-1">
            {isLoading ? (
              <div className="animate-pulse h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-20 ml-1"></div>
            ) : (
              <Typography className="text-xs text-neutral-body-2 group-hover:text-neutral-white">
                {primaryStatLabel || '-'}
              </Typography>
            )}
            {isLoading ? (
              <div className="animate-pulse h-12 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
            ) : (
              <Typography
                variant="h4"
                className="font-semibold text-cc-text-primary group-hover:text-neutral-white"
              >
                {formatter?.(primaryStat) ?? primaryStat}
              </Typography>
            )}
          </div>

          <div className="flex flex-col gap-1.5">
            {isLoading ? (
              <div className="animate-pulse h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
            ) : (
              <Typography className="text-xs text-neutral-body-2 group-hover:text-neutral-white">
                {secondaryStatLabel || '-'}
              </Typography>
            )}
            {isLoading ? (
              <div className="animate-pulse h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
            ) : (
              <Typography className="text-xl font-semibold text-cc-text-primary group-hover:text-neutral-white">
                {secondaryFormatter
                  ? secondaryFormatter(secondaryStat)
                  : formatter
                  ? formatter(secondaryStat)
                  : secondaryStat}
              </Typography>
            )}
            {isLoading ? (
              <div className="animate-pulse h-6 bg-gray-200 rounded-full dark:bg-gray-700 w-14"></div>
            ) : (
              <div className="flex">
                <Typography
                  className={`py-1 px-3 flex items-center text-xs font-medium rounded-xl text-neutral-white group-hover:text-neutral-body-2 group-hover:bg-neutral-white ${
                    (secondaryStatChange ?? 0) === 0
                      ? 'bg-neutral-body-1'
                      : (secondaryStatChange ?? 0) < 0
                      ? indicatorClass.down
                      : indicatorClass.up
                  }`}
                >
                  {(secondaryStatChange ?? 0) !== 0 && (
                    <Icon className="w-3 h-3 mr-1" />
                  )}
                  {!!secondaryStatChange
                    ? `${Intl.NumberFormat(undefined, {
                        style: 'percent',
                        notation: 'compact',
                      }).format((secondaryStatChange ?? 0) / 100)}`
                    : 'N/A'}
                </Typography>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </Link>
  )
}

const OverviewL2 = ({ data, fetchReady, ...rest }: OverviewLayoutProps) => {
  return (
    <BaseLayout {...rest}>
      {!fetchReady ? (
        <div className="w-full flex justify-center bg-neutral-white rounded-lg p-8 ">
          <ArrowPathIcon className="animate-spin text-primary-main w-8" />
        </div>
      ) : (
        <div className="grid gird-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          {data.map(d => (
            <OverviewItem key={d.cardLink} {...d} />
          ))}
        </div>
      )}
    </BaseLayout>
  )
}

export default OverviewL2
