import React from 'react'
import { format } from 'date-fns'

import Typography from '@components/atoms/typography'
import { UTCDate } from '@date-fns/utc'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { StatData } from '@interfaces/analytics-risk'
import { Tooltip } from '@material-tailwind/react'

const StatItem = ({
  value,
  label,
  classSetting,
}: {
  value?: number
  label: string
  classSetting?: { up: string; down: string }
}) => {
  const val = value || 0
  return (
    <div className="flex flex-col items-center py-8">
      <Typography
        className={`text-3xl font-semibold ${
          val === 0
            ? 'text-neutral-subtitle-1'
            : val > 0
            ? classSetting?.up ?? 'text-success-hover'
            : classSetting?.down ?? 'text-danger-hover'
        }`}
      >
        {`${val > 0 ? '+' : ''}${Intl.NumberFormat(undefined, {
          style: 'percent',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          notation: 'compact',
        }).format(val / 100)}`}
      </Typography>
      <Typography className="text-sm font-neutral-body-2 mt-3">
        {label}
      </Typography>
    </div>
  )
}

const StatisticL5 = ({
  description,
  cohort,
  monthOverMonth,
  qtrOverQtr,
  yearOverYear,
  monthOverMonthFormula,
  qtrOverQtrFormula,
  yearOverYearFormula,
  classSetting,
}: StatData) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-1 ">
      <div>
        <Typography className="text-lg font-semibold text-center flex justify-center">
          {monthOverMonthFormula && qtrOverQtrFormula && yearOverYearFormula && (
            <Tooltip
              content={
                <span>
                  Formula:
                  <br />
                  Month over Month: {monthOverMonthFormula}
                  <br />
                  Quarter over Quarter: {qtrOverQtrFormula}
                  <br />
                  Year over Year: {yearOverYearFormula}
                </span>
              }
              placement="right"
            >
              <InformationCircleIcon className="w-4 text-primary-main cursor-help mr-2" />
            </Tooltip>
          )}
          Summary Stats
        </Typography>
        <Typography className="text-xs font-neutral-body-2 text-center">
          {cohort ? `As of ${format(new UTCDate(cohort), 'PPP')}` : ''}
        </Typography>
        {description && (
          <Typography className="text-xs font-neutral-body-2 text-center">
            {description}
          </Typography>
        )}
      </div>
      <div className="border-b border-b-neutral-border-2 md:border-b-0 md:border-r lg:border-r-0 lg:border-b">
        <StatItem
          label="Month over Month"
          value={monthOverMonth}
          classSetting={classSetting}
        />
      </div>
      <div className="border-b border-y-neutral-border-2 md:border-b-0 md:border-r lg:border-r-0 lg:border-b">
        <StatItem
          label="Quarter over Quarter"
          value={qtrOverQtr}
          classSetting={classSetting}
        />
      </div>
      <div>
        <StatItem
          label="Year over Year"
          value={yearOverYear}
          classSetting={classSetting}
        />
      </div>
    </div>
  )
}

export default StatisticL5
