import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Button } from '@material-tailwind/react'
import { AuditService } from '@services/api-manage/audit'

interface MultiEmbedViewProps {
  itemsArray: any
  auditType: 'Payment' | 'Underwriting'
}

export const MultiEmbedView = ({
  itemsArray,
  auditType,
}: MultiEmbedViewProps) => {
  const { appliedFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const [idx, setIdx] = useState<number>(0)
  const { company } = useContext(AuthContext)
  const [url, setUrl] = useState<any>(false)

  //fetch attachment info
  const { data } = useQuery(
    ['attachment', [itemsArray, idx]],
    () =>
      AuditService.getAttachmentByKey({
        slug_name: company?.slug_name,
        key: itemsArray[idx].key,
        facility_id: activeFacilityId,
        audit_type: auditType,
      }),
    { ...getStaleMins() }
  )

  useEffect(() => {
    if (!!data) {
      const binaryData = new Uint8Array(data)
      const pdfBlob = new Blob([binaryData], { type: 'application/pdf' })
      const docUrl = URL.createObjectURL(pdfBlob)
      setUrl(docUrl)
    }
  }, [data])

  useEffect(() => {
    setIdx(0)
  }, [itemsArray])
  return (
    <div className="flex flex-col h-full w-full">
      {itemsArray && itemsArray.length > 0 ? (
        <div className="flex flex-col h-full">
          <div className="flex flex-row w-full items-center justify-left my-2 text-sm font-semibold">
            {itemsArray[idx]?.uploadType ?? ''}
          </div>
          {!!url && <iframe src={url} className="w-full h-full" />}

          <div
            className={`flex flex-row w-full items-center justify-${
              itemsArray.length > 1 ? 'between' : 'center'
            } mt-5`}
          >
            {itemsArray.length > 1 && (
              <Button disabled={0 == idx} onClick={() => setIdx(idx - 1)}>
                <ChevronLeftIcon className="w-5" />
              </Button>
            )}
            <span className="text-xs">
              {idx + 1} of {itemsArray.length}
            </span>
            {itemsArray.length > 1 && (
              <Button
                disabled={itemsArray.length == idx + 1}
                onClick={() => setIdx(idx + 1)}
              >
                <ChevronRightIcon className="w-5" />
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="h-full flex items-center justify-center">
          No evidence uploaded
        </div>
      )}
    </div>
  )
}
