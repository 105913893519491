import React, { useLayoutEffect } from 'react'

import * as am5 from '@amcharts/amcharts5'
import * as am5radar from '@amcharts/amcharts5/radar'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import Typography from '@components/atoms/typography'
import { greenToRed } from '@helpers/green-to-red'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'

interface RadarChartProps {
  value: string | number
  subtitle?: string
  id?: string
  percent: number
}

const RadarChart = ({ value, subtitle, percent, id }: RadarChartProps) => {
  const color = greenToRed(100 - percent - (percent >= 50 ? 20 : 0), true)

  useLayoutEffect(() => {
    let root = am5.array.find(
      am5.registry.rootElements,
      r => r.dom.id === (id ?? 'radar-chart')
    )
    if (!root) {
      root = am5.Root.new(id ?? 'radar-chart')
    }
    root.setThemes([am5themes_Animated.new(root)])
    root.container._settings.paddingTop = -24
    root.container._settings.paddingBottom = -24

    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360,
      })
    )

    const axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -40,
    })

    axisRenderer.labels.template.setAll({
      visible: false,
    })

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer,
      })
    )

    chart.bulletsContainer.set('mask', undefined)

    const axisRange0 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 0,
        endValue: percent,
      })
    )

    axisRange0.get('axisFill')?.setAll({
      visible: true,
      fill: am5.color(color.toString()),
    })

    axisRange0.get('label')?.setAll({
      forceHidden: true,
    })

    const axisRange1 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: percent,
        endValue: 100,
      })
    )

    axisRange1.get('axisFill')?.setAll({
      visible: true,
      fill: am5.color('#E4E7EC'),
    })

    axisRange1.get('label')?.setAll({
      forceHidden: true,
    })

    const axisRange2 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 49.75,
        endValue: 50.25,
      })
    )

    axisRange2.get('axisFill')?.setAll({
      visible: true,
      fill: am5.color('#FF0000'),
    })

    axisRange2.get('label')?.setAll({
      forceHidden: true,
    })

    root?._logo?.dispose()
    return () => {
      root?.dispose()
    }
  }, [value, percent])

  const Icon = percent >= 50 ? CheckCircleIcon : ExclamationTriangleIcon

  return (
    <div className="flex flex-col -mt-3">
      <div id={id ?? 'radar-chart'} className={`w-full min-h-[150px]`}></div>
      <div className="flex flex-col items-center justify-center w-full -mt-8">
        {subtitle && (
          <Typography
            className={`text-xl font-semibold text-neutral-black -translate-y-6 ${
              Number(subtitle.substring(0, subtitle.length - 1)) >= 100
                ? 'text-cc-positive'
                : 'text-cc-negative'
            }`}
          >
            {subtitle}
          </Typography>
        )}
        <div className="flex items-center">
          <Icon className="w-6 h-6 mr-2" style={{ color: color.toString() }} />
          <Typography className="text-l font-light text-cc-text-primary">
            {value.toLocaleString('en-US')} USD
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default RadarChart
