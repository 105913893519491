import React, { useContext, useEffect } from 'react'
import { format } from 'date-fns'
import { useQuery } from 'react-query'

import DatePicker from '@components/atoms/date-picker'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { normalizeDateToLocal } from '@helpers/date-utils'
import { getStaleMins } from '@helpers/stale-timer'
import { LayoutFilter } from '@interfaces/analytics-risk-filter-type-key'
import { MonitorFilterService } from '@services/api-manage/monitor-filter'

interface FilterCalculationDateProps {
  hideSelect?: boolean
}

const FilterCalculationDate = ({ hideSelect }: FilterCalculationDateProps) => {
  const {
    company,
    optionFilters,
    setOptionFilters,
    activeFilters,
    setActiveFilters,
    appliedFilters,
    setAppliedFilters,
  } = useContext(AuthContext)

  const { calculationDate } = activeFilters
  const { activeFacilityId, calculationDate: appliedCalculationDate } =
    appliedFilters
  const { calculationDateOption = [] } = optionFilters

  const params = {
    slug_name: company?.slug_name ?? '',
  }

  const { data, isFetching } = useQuery(
    ['calculation-date', params],
    () => MonitorFilterService.calculationDate(params),
    {
      ...getStaleMins(),
      enabled: !!activeFacilityId,
    }
  )

  const availableDates =
    calculationDateOption.map((cdo: { calculation_date: string }) =>
      normalizeDateToLocal(cdo.calculation_date)
    ) ?? []

  const onChange = (value: any) => {
    !!value &&
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        calculationDate: normalizeDateToLocal(
          format(new Date(value), 'yyyy-MM-dd')
        ),
      }))
  }

  useEffect(() => {
    if ((data ?? []).length > 0) {
      const filtered_data = data?.filter(
        d => d.facility_id === activeFacilityId
      )
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        calculationDate: normalizeDateToLocal(
          filtered_data?.[0]?.calculation_date
        ),
        initialDate: normalizeDateToLocal(filtered_data?.[0]?.calculation_date),
      }))
      setOptionFilters((prev: LayoutFilter) => ({
        ...prev,
        calculationDateOption: filtered_data,
        initialDate: normalizeDateToLocal(filtered_data?.[0]?.calculation_date),
      }))
      setAppliedFilters((prev: LayoutFilter) => ({
        ...prev,
        calculationDate: normalizeDateToLocal(
          filtered_data?.[0]?.calculation_date
        ),
        initialDate: normalizeDateToLocal(filtered_data?.[0]?.calculation_date),
      }))
    }
  }, [data, activeFacilityId])

  useEffect(() => {
    setOptionFilters((prev: LayoutFilter) => ({
      ...prev,
      calculationDateIsFetching: isFetching,
    }))
  }, [isFetching])

  useEffect(() => {
    setOptionFilters((prev: LayoutFilter) => ({
      ...prev,
      appliedCalculationDateOption: calculationDateOption,
    }))
  }, [appliedCalculationDate])

  // hide component if hideSelect is True
  if (hideSelect) {
    return <div></div>
  }

  return (
    <div className="filter__calcuation-date flex flex-col gap-1">
      <Typography className="text-neutral-body-2 text-sm">
        Calculation Date
      </Typography>
      {!isFetching ? (
        availableDates.length > 0 ? (
          <DatePicker
            value={new Date(calculationDate)}
            onChange={onChange}
            enabledDates={availableDates}
          />
        ) : (
          <Typography className="text-sm leading-[40px]">
            No dates available
          </Typography>
        )
      ) : (
        <Typography className="text-sm leading-[40px]">
          Loading Dates
        </Typography>
      )}
    </div>
  )
}
export default FilterCalculationDate
