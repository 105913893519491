import React from 'react'

interface Option {
  title: string
  active: boolean
  action: () => void
}

interface Group {
  options: Option[]
  title?: string
  class?: string
}

interface MultiOptionButtonGroupProps {
  buttonSelections: Group[]
}

/* A template for multi button with Cascade colors. Pass in a group and optional Title to be rendered as a multi-button. */
const MultiOptionButtonGroup: React.FC<MultiOptionButtonGroupProps> = ({
  buttonSelections,
}) => {
  return (
    <div className="flex gap-x-5 gap-y-3 flex-col md:flex-row flex-wrap mb-2">
      {buttonSelections.map((group, i) => (
        <div key={i} className="flex flex-col w-auto">
          {group.title && (
            <span className="mb-2 text-sm font-semibold">{group.title}</span>
          )}
          <div className="flex w-auto bg-cc-primary-gray-light rounded-full">
            {group.options.map((g, j) => (
              <button
                key={j}
                className={`block text-xs rounded-full px-4 py-1 text-cc-text-primary font-medium flex-grow border-2 border-neutral-white/0
                  ${
                    g.active
                      ? 'bg-primary-hover text-white border-cc-primary-orange border-2'
                      : 'hover:shadow-inner hover:border-2 hover:border-cc-primary-gray-light hover:bg-neutral-white'
                  }`}
                onClick={g.action}
              >
                {g.title}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default MultiOptionButtonGroup
