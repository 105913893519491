import { DependencyList, EffectCallback, useEffect, useRef } from 'react'
import isEqual from 'lodash/isEqual'

const useDeepCompareFilter = (
  callback: EffectCallback,
  dependencies: DependencyList
) => {
  const previousDependencies = useRef<DependencyList | undefined>(undefined)

  if (!isEqual(previousDependencies.current, dependencies)) {
    previousDependencies.current = dependencies
  }

  useEffect(callback, [previousDependencies.current])
}

export default useDeepCompareFilter
