import React from 'react'

import { Option, Select } from '@material-tailwind/react'

export interface ToggleInfo {
  label: string
  textInfo?: {
    primary: string
    secondary?: string
    tertiary?: string
  }
  active?: boolean
  action: () => void
}

// Toggle Props does not care if your choice is binary or multi, as long as your action function is properly designed
export interface ToggleProps {
  toggleTitle: string
  toggleSelections: ToggleInfo[]
}

/* A template for multi button with Cascade colors. Pass in a group and optional Title to be rendered as a multi-button. Multi-buttons above 4 will become a drop down! */
const MultiToggles: React.FC<{ mulitToggles: ToggleProps[] }> = ({
  mulitToggles,
}) => {
  return (
    <div className="flex gap-x-6 gap-y-3 flex-col md:flex-row flex-wrap w-full md:w-auto">
      {mulitToggles.map((toggle: ToggleProps, i) => (
        <div key={i} className="flex flex-col w-auto">
          {toggle.toggleTitle && (
            <span className="mb-2 text-sm font-semibold text-cc-text-primary">
              {toggle.toggleTitle}
            </span>
          )}
          {toggle.toggleSelections.length <= 4 && (
            <div className="flex w-auto bg-cc-primary-gray-light rounded-full">
              {toggle.toggleSelections.map((t: ToggleInfo, j) => (
                <button
                  key={j}
                  className={`block text-xs rounded-full  px-5 ${
                    toggle.toggleSelections.length <= 2 && 'px-7'
                  } py-[0.25rem] text-cc-text-primary font-medium flex-grow border-2 border-neutral-white/0
                  ${
                    t.active
                      ? 'bg-primary-hover text-white border-cc-primary-orange border-2'
                      : 'hover:shadow-inner hover:border-2 hover:border-cc-primary-gray-light hover:bg-neutral-white'
                  }`}
                  onClick={t.action}
                >
                  {t.label}
                </button>
              ))}
            </div>
          )}
          {toggle.toggleSelections.length > 4 && (
            <Select
              className="min-w-[8rem] h-[1.9rem]"
              value={toggle.toggleSelections.find(t => t.active)?.label || ''}
              onChange={(val: any) => {
                const selectedToggle = toggle.toggleSelections.find(
                  t => t.label === val
                )
                if (selectedToggle) {
                  selectedToggle.action()
                }
              }}
            >
              {toggle.toggleSelections.map((t: ToggleInfo) => (
                <Option key={t.label} value={t.label}>
                  {t.label}
                </Option>
              ))}
            </Select>
          )}
        </div>
      ))}
    </div>
  )
}

export default MultiToggles
