import React from 'react'

import { DotLottieReact } from '@lottiefiles/dotlottie-react'

const LoadingCascadeLogo = () => {
  return (
    <div className="w-20 h-20">
      <DotLottieReact src="/cascade_logo_loading.json" loop autoplay />
    </div>
  )
}

export default LoadingCascadeLogo
