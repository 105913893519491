import React from 'react'

import Typography from '@components/atoms/typography'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'

interface LoadingBarProps {
  field: string
  validation: boolean
  loadingCondition: boolean
  loadingLogic: React.ReactNode
  errorCondition: boolean
  renderDecimal?: React.ReactNode
}

const LoadingBar: React.FC<LoadingBarProps> = ({
  field,
  validation,
  loadingCondition,
  loadingLogic,
  errorCondition,
  renderDecimal = <span></span>,
}) => {
  if (!validation) {
    return null
  }

  return (
    <div className="rounded-lg bg-neutral-border-1 px-4 h-10 flex gap-4 justify-between items-center">
      <Typography
        className={`capitalize text-sm flex-1 ${
          !loadingCondition && 'text-cc-text-primary'
        }`}
      >
        {field}
        {renderDecimal}
      </Typography>
      {loadingCondition && (
        <div className="rounded h-4 flex-1 overflow-hidden border border-neutral-white">
          {loadingLogic}
        </div>
      )}
      {!loadingCondition &&
        (errorCondition ? (
          <ExclamationTriangleIcon className="text-cc-negative  w-6 h-6" />
        ) : (
          <CheckCircleIcon className="text-cc-positive  w-6 h-6" />
        ))}
    </div>
  )
}

export default LoadingBar
