import React, { createContext, ReactNode, useContext, useState } from 'react'

// Define the context with both activeTab and setActiveContextTab
const ActiveTabContext = createContext<{
  activeContextTab: number
  setActiveContextTab: (tab: number) => void
} | null>(null)

// Custom hook to use the ActiveTab context
export const useActiveTab = () => {
  const context = useContext(ActiveTabContext)
  if (!context) {
    throw new Error('useActiveTab must be used within an TabProvider')
  }
  return context
}

// Provider component that manages activeTab state and provides it to children
export const TabContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [activeContextTab, setActiveTab] = useState<number>(0) // Local state to manage the active tab

  const setActiveContextTab = (tab: number) => {
    setActiveTab(tab) // Function to update activeTab
  }

  return (
    <ActiveTabContext.Provider
      value={{ activeContextTab, setActiveContextTab }}
    >
      {children}{' '}
      {/* Any component inside TabProvider can now access activeTab */}
    </ActiveTabContext.Provider>
  )
}
