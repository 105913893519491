import React, { ReactNode, useEffect } from 'react'

interface StepperProps {
  activeStep: number
  children: ReactNode[]
  setIsLastStep: (value: any) => any
  setIsFirstStep: (value: any) => any
  setActiveStep: (val: number) => any
}

const Stepper: React.FC<StepperProps> = ({
  activeStep,
  children,
  setIsLastStep,
  setIsFirstStep,
  setActiveStep,
}) => {
  useEffect(() => {
    setIsLastStep(activeStep === children.length - 1)
    setIsFirstStep(activeStep === 0)
  }, [activeStep, children, setIsLastStep, setIsFirstStep])

  const len = (activeStep * 100) / (children.length - 1)

  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-col justify-center relative">
        <div className="mb-5 h-1 bg-cc-primary-gray-light rounded-lg mx-12">
          <div
            className={`transition-all ease-in-out duration-500 h-1 ${
              activeStep === children.length - 1
                ? 'bg-cc-primary-orange'
                : 'bg-cc-primary-gray-medium'
            } rounded-lg`}
            style={{
              width: `${len}%`,
            }}
          ></div>
        </div>
        <div className="w-full absolute -top-2.5 flex justify-between">
          {children.map((child: any, idx: number) => (
            <div key={idx}>
              <div
                className={`flex flex-col items-center ${
                  activeStep > idx ? 'cursor-pointer' : 'cursor-not-allowed'
                }`}
                onClick={() => {
                  idx < activeStep && setActiveStep(idx)
                }}
              >
                <div
                  className={`transition-all ease-in-out duration-300 p-3 rounded-full ${
                    activeStep === children.length - 1
                      ? 'bg-cc-primary-orange'
                      : activeStep > idx
                      ? 'bg-cc-primary-gray-medium'
                      : activeStep === idx
                      ? 'bg-cc-primary-gray-medium'
                      : 'bg-cc-primary-gray-light'
                  }`}
                />
                <div className="text-xs font-semibold pt-1">
                  {child.props.title}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="transition-all ease-in-out duration-500 mt-10">
        {children[activeStep]}
      </div>
    </div>
  )
}

export default Stepper
