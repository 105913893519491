import React, { FC } from 'react'

interface L5ChartStatProps {
  toolTip?: JSX.Element
  multiOption?: JSX.Element
  /**
   * chart content
   */
  chart: JSX.Element
  /**
   * L5 stat content
   */
  stat?: JSX.Element
  showData?: JSX.Element
}

/**
 * Base layout for L5 that has chart and stat
 * @param {L5ChartStatProps} props Component properties
 * @returns JSX
 */
const L5ChartstatLayout: FC<L5ChartStatProps> = ({
  toolTip,
  multiOption,
  chart,
  stat,
  showData,
}) => {
  return (
    <div className="flex flex-col gap-6">
      {(toolTip || multiOption) && (
        <div className="flex flex-col gap-3 md:flex-row justify-between">
          {toolTip}
          <div className="md:ml-auto">{multiOption}</div>
        </div>
      )}
      <div className="flex flex-col gap-3 lg:flex-row lg:items-center">
        <div className="flex-1 w-full lg:w-[calc(100%-167px)]">{chart}</div>
        {stat && (
          <div className="border-t border-t-neutral-border-2 lg:border-0">
            {stat}
          </div>
        )}
      </div>
      {showData}
    </div>
  )
}

export default L5ChartstatLayout
